<template>
  <div>
    <div class="row align--center">
      <div
        class="flex xs12 md6"
        v-if="withSearch"
      >
        <va-input
          v-model="term"
          :placeholder="$t('tables.actions.search')"
          removable
        >
          <va-icon
            name="fa fa-search"
            slot="prepend"
          />
        </va-input>
      </div>

      <div class="flex xs12 md2" v-if="withPager">
        <va-select
          v-model="perPage"
          :label="$t('tables.rows')"
          :options="perPageOptions"
          noClear
        />
      </div>

      <slot name="top">
        <div class="flex xs12 md2 offset--md2">
          <va-button
            flat
            small
            color="primary"
            icon="fa fa-plus"
            v-if="top('new') && currentUser.can(permissionController, 'add')"
            :to="getRoute('New')"
          >
            {{ $t('tables.actions.new') }}
          </va-button>
        </div>
      </slot>
    </div>
    <data-table
      ref="remotetable"
      :fields="columns"
      :data="data"
      :loading="loading"
      :per-page="parseInt(perPage)"
      :totalPages="totalPages"
      :no-data-label="$t('tables.empty')"
      @sort-change="onSortChange"
      @page-selected="onPageChange"
      api-mode
    >
      <template v-slot:status="props">
        <slot name="row-status" :props="props">
          <va-badge :color="props.rowData.color">
            {{ props.rowData.status }}
          </va-badge>
        </slot>
      </template>

      <template v-slot:actions="props">
        <slot name="row-preactions" :props="props"></slot>
        <slot
          name="row-actions"
          :props="props"
        >
          <va-popover
            v-if="action('view') && currentUser.can(permissionController, 'view')"
            :message="$t('tables.actions.view')"
            placement="left"
          >
            <va-button
              flat
              small
              color="primary"
              icon="fa fa-search-plus"
              :to="getRoute('View', props.rowData)"
              @click.prevent="emitAction('view', props.rowData)"
            />
          </va-popover>
          <va-popover
            v-if="action('view2') && currentUser.can(permissionController, 'view')"
            :message="$t('tables.actions.view')"
            placement="left"
          >
            <va-button
              flat
              small
              color="primary"
              icon="fa fa-search-plus"
              @click.prevent="onAction('view_two', props.rowData, props.rowIndex)"

            />
          </va-popover>
          <va-popover
            v-if="action('edit') && currentUser.can(permissionController, 'edit')"
            :message="$t('tables.actions.edit')"
            placement="left"
          >
            <va-button
              flat
              small
              color="secondary"
              icon="fa fa-edit"
              :to="getRoute('Edit', props.rowData)"
              @click.prevent="emitAction('edit', props.rowData)"
            />
          </va-popover>
          <va-popover
            v-if="action('delete') && currentUser.can(permissionController, 'delete')"
            :message="$t('tables.actions.delete')"
            placement="left"
          >
            <va-button
              flat
              small
              color="danger"
              icon="fa fa-trash"
              @click.prevent="onAction('delete-item', props.rowData, props.rowIndex)"
            />
          </va-popover>
          <va-popover
            v-if="action('download')"
            :message="$t('tables.actions.download_certificate')"
            placement="left"
          >
            <va-button
              flat
              small
              color="success"
              icon="fa fa-download"
              @click.prevent="$emit('download-item', slotProps.rowData)"
            />
          </va-popover>
        </slot>
        <slot name="row-postactions" :props="props"></slot>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const DataTable = () => import(/* webpackPrefetch: true */ './DataTable')

export default {
  name: 'remote-table',
  components: {
    DataTable,
  },
  props: {
    local: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    withSearch: {
      type: Boolean,
      default: true,
    },
    withPager: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    topOptions: {
      type: Array,
      default: () => ['new'],
    },
    actionOptions: {
      type: Array,
      default: () => [
        'view', 'edit', 'delete',
      ],
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    pagination: {
      type: Object,
      default: () => ({
        count: 0,
        page_count: 1,
      }),
    },
    crudLinks: {
      type: String,
      default: '',
    },
    controller: {
      type: String,
      default: '',
    },
    queries: {
      type: String,
      required: false,
    },
    sortField: {
      type: String,
      required: false,
    },
    sortType: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      term: null,
      perPage: '50',
      perPageOptions: ['5', '10', '20', '50'],
      baseServerParams: {
        perPage: 50,
        page: 1,
        sort: {
          field: '',
          type: '',
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    permissionController () {
      let controller = this.controller
      if (controller.length === 0) {
        controller = this.crudLinks
      }
      return this.normalize(controller)
    },
    totalPages () {
      return this.pagination.page_count || 1
    },
    totalRecords () {
      return this.pagination.count || 0
    },
    serverParams () {
      return Object.assign(this.baseServerParams, this.params)
    },
    slotNames () {
      return this.columns.map(x => x.name)
    },
  },
  watch: {
    perPage (val) {
      this.updateParams('perPage', parseInt(val))
    },
    queries (val) {
      this.updateParams('queries', val)
    },
    term (val) {
      this.onSearch(val)
    },
    sortField (val) {
      if (!val) return
      this.baseServerParams.sort.field = val
    },
    sortType (val) {
      if (!val) return
      this.baseServerParams.sort.type = val
    },
  },
  created () {
    if (this.sortField && this.sortType) {
      this.baseServerParams.sort = {
        field: this.sortField,
        type: this.sortType,
      }
    }
    this.loadItems()
  },
  methods: {
    top (button) {
      return this.topOptions.some(t => t === button)
    },
    action (button) {
      return this.actionOptions.some(a => a === button)
    },
    emitAction (action, props) {
      if (!this.local) return

      const eventAction = action.toLowerCase()
      this.$emit(`${eventAction}-item`, props)
    },
    getRoute (action, props) {
      if (this.local) {
        return null
      }

      let params = null
      if (props) {
        params = {
          id: props.id,
        }
      }
      return { name: this.crudLinks + action, params }
    },
    updateParams (property, value) {
      this.baseServerParams[property] = value
      this.loadItems()
    },
    onSearch (event) {
      this.$emit('search-data', event)
    },
    onPageChange (page = 1) {
      this.updateParams('page', page)
    },
    onAction (action, data, index) {
      this.$emit(action, data, index)
    },
    onSortChange (sort) {
      this.baseServerParams.page = 1
      this.updateParams('sort', { field: sort[0].sortField, type: sort[0].direction })
    },
    loadItems () {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      this.$emit('update-data', this.serverParams)
    },
    capitalize (s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    normalize (word) {
      let full = ''
      word.split('-').forEach(w => {
        full += this.capitalize(w)
      })
      return full
    },
  },
}
</script>
